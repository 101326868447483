import React, { useState } from 'react';
import './charge-bank.screen.css';


export const MasavCharge = () => {
    const [institutionNumber, setInstitutionNumber] = useState('21097621');
    const [companyName, setCompanyName] = useState('זינאת אונליין בע"מ');
    const [bankNumber, setBankNumber] = useState('');
    const [branchNumber, setBranchNumber] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [idNumber, setIdNumber] = useState('');
    const [identifier, setIdentifier] = useState('');
    const [amount, setAmount] = useState('');

          // Function to handle file generation and download
    const handleGenerateFile = () => {
        // Example of constructing a single transaction record
        const transactionRecord = `${institutionNumber},${companyName},${bankNumber},${branchNumber},${accountNumber},${idNumber},${identifier},${amount}`;

        // Construct the complete file content
        const fileContent = `Header Information\r\n${transactionRecord}\r\nSummary Information`;

        // Convert to Blob and create a URL for download
        const blob = new Blob([fileContent], { type: 'text/plain;charset=utf-8' });
        const fileUrl = URL.createObjectURL(blob);

        // Create a link and trigger the download
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = 'MasavFile.dat';
        link.click();

        // Cleanup
        URL.revokeObjectURL(fileUrl);
    };

    return (
        <div className='form-container'>
            <h1>פרטי חיובים</h1>
       
            <form>
                <div>
                    <label>מספר מוסד:
                        <input type="number" value={institutionNumber} onChange={(e) => setInstitutionNumber(e.target.value)} />
                    </label>
                </div>
                <div>
                    <label>שם חברה:
                        <input type="text" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                    </label>
                </div>
                <div>
                    <label>בנק:
                        <input type="number" value={bankNumber} onChange={(e) => setBankNumber(e.target.value)} />
                    </label>
                </div>
                <div>
                    <label>סניף:
                        <input type="number" value={branchNumber} onChange={(e) => setBranchNumber(e.target.value)} />
                    </label>
                </div>
                <div>
                    <label>חשבון:
                        <input type="number" value={accountNumber} onChange={(e) => setAccountNumber(e.target.value)} />
                    </label>
                </div>
                <div>
                    <label>ח.פ./ת.ז.:
                        <input type="number" value={idNumber} onChange={(e) => setIdNumber(e.target.value)} />
                    </label>
                </div>
                <div>
                    <label>מספר מזהה:
                        <input type="number" value={identifier} onChange={(e) => setIdentifier(e.target.value)} />
                    </label>
                </div>
                <div>
                    <label>סכום לחיוב:
                        <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} />
                    </label>
                </div>
                <button type="button" onClick={handleGenerateFile}>הפק קובץ</button>
            </form>
        </div>
    )
}